<template>
  <CardTable
    :title="title"
    :subTitle="subTitle"
    :searchText="searchText"
    :columns="table.columns"
    :rows="table.rows"
    :isLoading="table.isLoading"
    :pageSize="serverParams.pageSize"
    :pageNumber="serverParams.pageNumber"
    :totalPage="table.totalPage"
    :totalRecords="table.totalRecords"
    @onRowSelected="onRowSelected"
    @onPerPageChange="onPerPageChange"
    @onPageChange="onPageChange"
    @onSearch="onSearch"
  >
    <template #toolbar>
      <b-button variant="primary" :to="{ name: formName }">
        Registrasi Member Baru
      </b-button>
    </template>
    <template #empty>
      <EmptyTable
        title="Belum ada data member"
        :description="subTitle"
        button-label="Registrasi Member Baru"
        :href="{ name: formName }"
      />
    </template>
    <template #cell(image)="data">
      <div
        v-if="data.item.image != null"
        @click="show(data.item.image?.label)"
        class="symbol symbol-45 symbol-light"
      >
        <span class="symbol-label">
          <img :src="mediaUrl(data.item.image.label)" height="32" />
        </span>
      </div>
    </template>
    <template #cell(identity)="data">
      <span>
        {{ data.item?.nomorIdentitas ?? "-" }}
      </span>
      <br /><small>
        {{ data.item?.identityType?.label ?? "-" }}
      </small>
    </template>
    <template #cell(gender)="data">
      {{ data.value.label }}
    </template>
    <template #cell(birth)="data">
      <span v-if="!data.item.placeOfBirth && !data.item.dateOfBirth">-</span>
      <span v-else>
        {{
          `${data.item.placeOfBirth ?? "-"}, ${dateFormat(
            data.item.dateOfBirth,
            "DD MMMM YYYY"
          )}`
        }}
      </span>
    </template>
    <template #cell(createdAt)="data">
      {{ dateFormat(data.item.created.createdAt, "DD MMMM YYYY") }}
    </template>
    <template #cell(memberCard)="data">
      {{ data.value.cardNumber }}
      <br /><small>{{
        dateRange(data.value.validFrom, data.value.validUntil)
      }}</small>
    </template>
  </CardTable>

  <!-- <viewer :images="images">
    <img v-for="src in images" :key="src" :src="src" />
  </viewer> -->
</template>

<script>
import { mapGetters } from "vuex";
import { member as columns } from "@/core/datasource/column";
import { pageSize, mediaUrl, dateFormat, dateRange } from "@/core/utils";

export default {
  data: () => ({
    formName: "memberCreate",
    title: "Member",
    subTitle: "Semua member akan ditampilkan disini ya",
    searchText: "Cari member",
    serverParams: {
      pageNumber: 1,
      pageSize,
      fullName: null,
      actived: null,
      orderBy: "createdAt desc",
    },
    table: {
      isLoading: false,
      columns,
      rows: [],
      totalPage: 0,
      totalRecords: 0,
    },
    images: [],
  }),
  computed: {
    ...mapGetters(["store"]),
  },
  watch: {
    store() {
      this.getAll();
    },
  },
  created() {
    this.getAll();
  },
  methods: {
    mediaUrl,
    dateFormat,
    dateRange,
    show(image) {
      this.$viewerApi({
        images: image,
      });
    },
    getAll() {
      const self = this;

      self.table.isLoading = true;
      self.$store
        .dispatch("apis/get", {
          url: `store/${self.store.id}/member`,
          params: self.serverParams,
        })
        .then((response) => {
          if (response.error) {
            self.$message.error({
              zIndex: 10000,
              message: response.message,
            });
          } else {
            self.table.rows = response.data.data;
            self.table.totalPage = response.data.totalPage;
            self.table.totalRecords = response.data.totalData;
          }
          self.table.isLoading = false;
        });
    },
    updateParams(newProps) {
      const self = this;
      self.serverParams = Object.assign({}, self.serverParams, newProps);
    },
    onRowSelected(items) {
      const self = this;
      self.$router.push({
        name: "memberUpdate",
        params: {
          id: items[0].id,
        },
      });
    },
    onPerPageChange(params) {
      const self = this;
      self.updateParams({ pageSize: params, pageNumber: 1 });
      self.getAll();
    },
    onPageChange(params) {
      const self = this;
      self.updateParams({ pageNumber: params });
      self.getAll();
    },
    onSearch(params) {
      const self = this;
      self.updateParams({
        fullName: params,
      });
      self.getAll();
    },
    onFilter() {
      const self = this;
      self.getAll();
    },
  },
};
</script>
